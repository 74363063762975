import { Label, WhiteLabelConfig } from '../../../generated/apps-api';
import { AppInfo, LabelsObject, SharedModelError, SharedResults, SharedStateModel } from './shared.model';


export enum SharedAction {
  SET_CURRENT_APP_INFO = '[Set Current App Info] Sets the current app info',
  SET_CURRENT_APP_NAME = '[Set Current App Info] Sets the current app name',
  COMPLETE_LOADING = '[Complete Loading] Marks loading state as being done',
  SET_TEST_MODE = '[Set Test Mode] Sets a new test mode value in the state',
  UPDATE_TEST_MODE = '[Update Test Mode] Updates and persists the current user test mode value',
  JUMPED_STEPS = '[Set Jumped Steps] Sets the jumped steps to be used on notification bar',
  SET_STATIC_LABELS = '[Set Static Labels] Sets the static labels',
  LOAD_RULE_SETS_LABELS = '[Load Rule Sets Labels] Loads main labels of the request rule sets',
  ADD_RULE_SETS_LABELS = '[Add Rule Sets Labels] Adds additional labels to the rule sets labels',
  SET_ERRORS_CONTEXT = '[Set Errors Context] Sets Errors to be used on errors page',
  SET_STEPS_TO_GO_BACK_ON_ERROR = '[Set Steps to go back on error] Sets Steps to Go Back on Error. Default is 1',
  SET_SKIP_NEXT_ERROR_HANDLING = '[Set Skip Next Error Handling] Sets  the value that allows error handling to be skipped or not',
  SET_AUDIT_RESULT_LABELS = '[Set Audit Result Labels] Sets the audit-save result labels state',
  SET_SHARED_RESULTS_RESULT_LABELS = '[Set Shared Result Labels] Sets the shared result labels state',
  SET_WHITE_LABEL_CONFIG = '[Set White Label Config] Sets the white label configuration',
  SET_LOGGED_IN = '[Set Logged In] Sets the logged-in to true',
  SET_EXTERNAL_GRANT = '[Set External access] Sets external grant to true',
  SET_SHARED_RESULTS = '[Set Shared Results] Sets Shared Results',
  SHARED_START_AGAIN = '[Set Shared Start again] Sets Shared Start Again',
  LOAD_USER_PREFERENCES = '[Load User Preferences] Loads the current user preferences',
  UPDATE_USER_LANGUAGE = '[Update user language] Updates the user language',
  UPDATE_USER_LEGAL_ENTITY = '[Update user legal entity] Updates the user legal entity',
  SET_AUDIT_CORRELATION_ID = '[Audit] set the current correlation id',
  SET_WHITE_LABEL_CONFIG_AVAILABLE = '[Set White Label Config] set white label config available',
  SET_CURRENT_WHITE_LABEL_COMPANY = '[Set Current white label company] set Current white label company'
}

export class SetCurrentAppInfo {
  public static readonly type = SharedAction.SET_CURRENT_APP_INFO;

  constructor(public payload: { appInfo: AppInfo }) {
  }
}

export class SetCurrentAppName {
  public static readonly type = SharedAction.SET_CURRENT_APP_NAME;

  constructor(public payload: { appName: string }) {
  }
}


export class CompleteLoading {
  public static readonly type = SharedAction.COMPLETE_LOADING;

  constructor() {
  }
}

export class SetTestMode {
  public static readonly type = SharedAction.SET_TEST_MODE;

  constructor(public payload: { testMode: boolean }) {
  }
}

export class UpdateTestMode {
  public static readonly type = SharedAction.UPDATE_TEST_MODE;

  constructor(public payload: { testMode: boolean }) {
  }
}

export class SetErrors {
  public static readonly type = SharedAction.SET_ERRORS_CONTEXT;

  constructor(public payload?: { errors?: SharedModelError }) {
  }
}

export class SetStepsToGoBackOnError {
  public static readonly type = SharedAction.SET_STEPS_TO_GO_BACK_ON_ERROR;

  constructor(public payload?: number) {
  }
}

export class SetSkipNextErrorHandling {
  public static readonly type = SharedAction.SET_SKIP_NEXT_ERROR_HANDLING;

  constructor(public payload?: { value: boolean }) {
  }
}

export class SetStaticLabels {
  public static readonly type = SharedAction.SET_STATIC_LABELS;

  constructor(public payload: { labels: { [key: string]: Label } }) {
  }
}

export class LoadRuleSetLabels {
  public static readonly type = SharedAction.LOAD_RULE_SETS_LABELS;

  constructor(public payload: { jurisdiction: string; ruleSetType: string; labels: LabelsObject }) {
  }
}

export class AddRuleSetLabels {
  public static readonly type = SharedAction.ADD_RULE_SETS_LABELS;

  constructor(
    public payload: { jurisdiction: string; contentProviderId: string; ruleSetType: string; labelsIds: string[] }
  ) {
  }
}

export class JumpedSteps {
  public static readonly type = SharedAction.JUMPED_STEPS;

  constructor(public payload: { jumpedSteps: number[] }) {
  }
}

export class SetAuditResultLabels {
  public static readonly type = SharedAction.SET_AUDIT_RESULT_LABELS;

  constructor(public payload?: { state: Partial<SharedStateModel> }) {
  }
}

export class SetSharedResultsLabels {
  public static readonly type = SharedAction.SET_SHARED_RESULTS_RESULT_LABELS;

  constructor(public payload?: { state: Partial<SharedStateModel> }) {
  }
}

export class SetWhiteLabelConfig {
  public static readonly type = SharedAction.SET_WHITE_LABEL_CONFIG;

  constructor(public payload?: { config: WhiteLabelConfig }) {
  }
}

export class SetLoggedIn {
  public static readonly type = SharedAction.SET_LOGGED_IN;

  constructor(public payload?: { state: boolean }) {
  }
}

export class SetExternalGrant {
  public static readonly type = SharedAction.SET_EXTERNAL_GRANT;

  constructor(public payload?: { state: boolean }) {
  }
}

export class SetSharedResults {
  public static readonly type = SharedAction.SET_SHARED_RESULTS;

  constructor(public payload?: { state: SharedResults }) {
  }
}

export class SharedStartAgain {
  public static readonly type = SharedAction.SHARED_START_AGAIN;

  constructor() {
  }
}

export class LoadUserPreferences {
  public static readonly type = SharedAction.LOAD_USER_PREFERENCES;

}

export class UpdateUserLanguage {
  public static readonly type = SharedAction.UPDATE_USER_LANGUAGE;

  constructor(public language: string) {
  }
}

export class UpdateUserLegalEntity {
  public static readonly type = SharedAction.UPDATE_USER_LEGAL_ENTITY;

  constructor(public legalEntityId: string) {
  }
}

export class SetAuditCorrelationId {
  public static readonly type = SharedAction.SET_AUDIT_CORRELATION_ID;

  constructor(public readonly correlationId?: string) {
  }
}

export class SetWhiteLabelConfigAvailable {
  public static readonly type = SharedAction.SET_WHITE_LABEL_CONFIG_AVAILABLE;

  constructor(public readonly isWhiteLabelConfigAvailable?: boolean) {
  }
}

export class SetCurrentWhiteLabelCompany {
  public static readonly type = SharedAction.SET_CURRENT_WHITE_LABEL_COMPANY;

  constructor(public readonly companyId?: string) {
  }
}

