import { distinct } from '../../utils/arrays.utils';
import { LabelsObject, TaxonomyVariable } from '../shared.model';


export const replaceKnownVariablesFromLabels = (labels: LabelsObject, taxonomyVariables: TaxonomyVariable[]): {
  labelObject: LabelsObject,
  changedLabelKeys: string[]
}  => {

  const changedLabelKeys: string[] = [];
  if (Object.keys(taxonomyVariables || {}).length === 0) {
    return { labelObject: labels, changedLabelKeys: changedLabelKeys };
  }
  // eslint-disable-next-line guard-for-in
  for (const labelsKey in labels) {
    labels[labelsKey].title = replaceKnownVariables(labels[labelsKey].title, taxonomyVariables, labelsKey, changedLabelKeys);
    labels[labelsKey].description = replaceKnownVariables(labels[labelsKey].description, taxonomyVariables, labelsKey, changedLabelKeys);
    if (labels[labelsKey].translations) {
      // eslint-disable-next-line guard-for-in
      for (const translationsKey in labels[labelsKey].translations) {
        labels[labelsKey].translations[translationsKey].title = replaceKnownVariables(labels[labelsKey].translations[translationsKey].title, taxonomyVariables, labelsKey, changedLabelKeys);
        labels[labelsKey].translations[translationsKey].description = replaceKnownVariables(labels[labelsKey].translations[translationsKey].description, taxonomyVariables, labelsKey, changedLabelKeys);
      }
    }
  }
  return { labelObject: labels, changedLabelKeys: changedLabelKeys.filter(distinct) };
};

const replaceKnownVariables = (result: string, taxonomyVariables: TaxonomyVariable[], labelKey: string, changedLabelKeys: string[]):string => {

  if (result?.includes(`\${`)) {
    taxonomyVariables.forEach(variable => result = result?.replaceAll(variable.identifier, variable.value));
    changedLabelKeys.push(labelKey);
    return result;
  }
  return result;
}
