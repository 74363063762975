import { alpha3ToAlpha2 } from 'i18n-iso-countries';

export const apiaxAlpha3ToAlpha2 = (alpha3: string): string => {
  if (alpha3 === 'GLOBAL') return 'global';
  const hasState = alpha3.split('_')?.length > 1;
  if (hasState) {
    alpha3 = alpha3.split('_')?.[0];
  }
  return alpha3ToAlpha2(alpha3)?.toLowerCase();
};
