import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { merge, cloneDeep } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { RuleSet } from 'src/app/shared/state/shared.model';
import {
  Attribute,
  DataTransferAppContextApiService,
  DataTransferLabelsApiService
} from '../../../generated/apps-api';
import {
  CompleteLoading,
  LoadRuleSetLabels,
  SetErrors,
  SetStaticLabels,
  SetTestMode
} from '../../shared/state/shared.action';
import {
  ClearFollowingRuleSets,
  ClearMainFlowContext,
  InitMainFlowJurisdiction,
  InitNewJurisdictionFlow,
  LoadContext,
  LoadDataTransferLabels,
  LoadTerritorialScopeLabels,
  SetCurrentJurisdiction,
  SetCurrentRuleSet,
  SetCurrentScope,
  SetDataClassContext,
  SetDataClassificationContext,
  SetGeneralAttribute,
  SetMainFlowContext,
  SetSenderReceiverAvailableJurisdictions,
  SetSenderReceiverJurisdictions,
  SetStaticResult,
  StartAgain
} from './data-transfer.action';
import { DEFAULT_STATE } from './data-transfer.defaultState';
import { DataTransferStateModel, JurisdictionContext, MainFlowContext, RuleSetContext } from './data-transfer.model';

@State<DataTransferStateModel>({
  name: 'dataTransfer',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class DataTransferState {
  constructor(
    private contextService: DataTransferAppContextApiService,
    private dataTransferLabelsApiService: DataTransferLabelsApiService,
    private router: Router,
    private store: Store
  ) { }

  @Selector()
  public static jurisdictions(state: DataTransferStateModel): string[] {
    return state.availableJurisdictions;
  }

  @Selector()
  public static allJurisdictions(state: DataTransferStateModel): string[] {
    return state.allJurisdictions;
  }

  @Selector()
  public static generalAttributes(state: DataTransferStateModel): Attribute[] {
    return state.generalAttributes;
  }

  @Selector()
  public static senderJurisdictions(state: DataTransferStateModel): string[] {
    return state.senderJurisdictions;
  }

  @Selector()
  public static currentRuleSet(state: DataTransferStateModel): RuleSet {
    return state.currentRuleSet;
  }

  @Selector()
  public static mainFlowContext(state: DataTransferStateModel): MainFlowContext {
    return state.mainFlowContext;
  }

  @Selector()
  public static receiverJurisdictions(state: DataTransferStateModel): string[] {
    return state.receiverJurisdictions;
  }

  @Selector()
  public static senderAvailableJurisdictions(state: DataTransferStateModel): string[] {
    return state.senderAvailableJurisdictions;
  }

  @Selector()
  public static receiverAvailableJurisdictions(state: DataTransferStateModel): string[] {
    return state.receiverAvailableJurisdictions;
  }

  @Selector()
  public static currentJurisdiction(state: DataTransferStateModel): string {
    return state.currentJurisdiction;
  }

  @Selector()
  public static currentScope(state: DataTransferStateModel): 'sender' | 'receiver' {
    return state.currentScope;
  }

  @Action(SetSenderReceiverAvailableJurisdictions)
  public setSenderReceiverAvailableJurisdictions(
    context: StateContext<DataTransferStateModel>,
    { payload }: SetSenderReceiverAvailableJurisdictions
  ) {
    return context.patchState({
      ...payload,
      senderJurisdictions: payload.senderAvailableJurisdictions,
      receiverJurisdictions: payload.receiverAvailableJurisdictions,
      generalAttributes: DEFAULT_STATE.generalAttributes,
    });
  }

  @Action(SetSenderReceiverJurisdictions)
  public setSenderReceiverJurisdictions(
    context: StateContext<DataTransferStateModel>,
    { payload }: SetSenderReceiverJurisdictions
  ) {
    return context.patchState({
      ...payload,
      generalAttributes: DEFAULT_STATE.generalAttributes,
    });
  }

  @Action(SetGeneralAttribute)
  public setGeneralAttribute(context: StateContext<DataTransferStateModel>, { payload }: SetGeneralAttribute) {
    const generalAttributes = context
      .getState()
      .generalAttributes.filter((attr) => attr.name !== payload.newAttribute.name)
      .concat(payload.newAttribute);

    return context.patchState({ generalAttributes });
  }

  @Action(SetDataClassificationContext)
  public setDataClassificationContext(context: StateContext<DataTransferStateModel>, { payload }: SetDataClassContext) {
    const { currentJurisdiction, mainFlowContext } = context.getState();
    const newContext = cloneDeep(mainFlowContext);
    payload.ruleSet = RuleSet.DATA_CLASSIFICATION;
    newContext[currentJurisdiction].dataClassification = payload;

    return context.patchState({ mainFlowContext: newContext });
  }

  @Action(SetMainFlowContext)
  public setMainFlowContext(context: StateContext<DataTransferStateModel>, { payload }: SetMainFlowContext) {
    const { currentJurisdiction, currentScope, mainFlowContext } = context.getState();
    const jurisdictionContext = cloneDeep(context.getState().mainFlowContext?.[currentJurisdiction] || {});
    const ruleSetContextsArray = jurisdictionContext?.[currentScope] || [];
    const ruleSetIndex = ruleSetContextsArray.findIndex((r) => r.ruleSet === payload.ruleSet);

    const newContext = cloneDeep(mainFlowContext);
    if (ruleSetIndex > -1) {
      ruleSetContextsArray[ruleSetIndex] = payload;
      newContext[currentJurisdiction][currentScope][ruleSetIndex] = payload;
    } else {
      newContext[currentJurisdiction][currentScope].push(payload);
    }

    return context.patchState({ mainFlowContext: cloneDeep(newContext) });
  }

  @Action(InitMainFlowJurisdiction)
  public initMainFlowJurisdiction(
    context: StateContext<DataTransferStateModel>,
    { payload }: InitMainFlowJurisdiction
  ) {
    const emptyModel: DataTransferStateModel = {
      mainFlowContext: {
        [payload]: {
          sender: [
            {
              questions: [],
              request: [],
              ruleSet: RuleSet.DATA_TRANSFER_CONDITION,
            },
            {
              questions: [],
              request: [],
              ruleSet: RuleSet.DATA_TRANSFER,
            },
            {
              questions: [],
              request: [],
              ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER,
            },
          ],
          receiver: [
            {
              questions: [],
              request: [],
              ruleSet: RuleSet.DATA_TRANSFER_CONDITION,
            },
            {
              questions: [],
              request: [],
              ruleSet: RuleSet.DATA_TRANSFER,
            },
            {
              questions: [],
              request: [],
              ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER,
            },
          ],
          dataClassification: {
            questions: [],
            request: [],
          },
        },
      },
    };
    const newModel = merge(cloneDeep(context.getState().mainFlowContext), emptyModel.mainFlowContext);

    return context.patchState({ mainFlowContext: newModel });
  }

  @Action(ClearFollowingRuleSets)
  public clearFollowingRuleSets(context: StateContext<DataTransferStateModel>, { payload }: ClearFollowingRuleSets) {
    const { currentRuleSet, currentJurisdiction, currentScope } = payload?.jurisdiction
      ? {
          currentRuleSet: undefined,
          currentJurisdiction: payload.jurisdiction,
          currentScope: 'sender' as 'sender' | 'receiver',
        }
      : context.getState();

    const { mainFlowContext } = cloneDeep(context.getState());
    const jurisdictionContext: JurisdictionContext = mainFlowContext[currentJurisdiction];

    const clearRuleSet = (ruleSet: RuleSet, scope: 'receiver' | 'sender') => {
      const clearItem = (item: RuleSetContext): RuleSetContext => {
        item.questions = [];
        item.request = [];
        delete item.actions;
        delete item.inputs;
        delete item.ruleSetInfo;
        delete item.ruleSetDataTransfer;
        return item;
      };
      jurisdictionContext?.[scope]?.forEach((item) => (item.ruleSet === ruleSet ? clearItem(item) : item));
      if (ruleSet === RuleSet.DATA_CLASSIFICATION && jurisdictionContext?.dataClassification !== undefined)
        jurisdictionContext.dataClassification = clearItem(jurisdictionContext.dataClassification);

      if (scope === 'receiver')
        jurisdictionContext?.receiver?.forEach((r) => {
          const correspondingSenderRequest = jurisdictionContext?.sender?.find((s) => s.ruleSet === r.ruleSet).request;
          if (correspondingSenderRequest.length > 0) r.request = correspondingSenderRequest;
        });
    };

    const ruleSetsToClear: { ruleSet: RuleSet; scope: 'receiver' | 'sender' }[] = [];
    switch (currentRuleSet) {
      case undefined:
        ruleSetsToClear.push({ ruleSet: RuleSet.DATA_CLASSIFICATION, scope: currentScope });
        ruleSetsToClear.push({ ruleSet: RuleSet.DATA_TRANSFER_CONDITION, scope: currentScope });
        ruleSetsToClear.push({ ruleSet: RuleSet.DATA_TRANSFER, scope: currentScope });
        ruleSetsToClear.push({ ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER, scope: currentScope });
        break;
      case RuleSet.DATA_CLASSIFICATION:
        ruleSetsToClear.push({ ruleSet: RuleSet.DATA_TRANSFER_CONDITION, scope: currentScope });
        ruleSetsToClear.push({ ruleSet: RuleSet.DATA_TRANSFER, scope: currentScope });
        ruleSetsToClear.push({ ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER, scope: currentScope });
        break;
      case RuleSet.DATA_TRANSFER_CONDITION:
        ruleSetsToClear.push({ ruleSet: RuleSet.DATA_TRANSFER, scope: currentScope });
        ruleSetsToClear.push({ ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER, scope: currentScope });
        break;
      case RuleSet.DATA_TRANSFER:
        ruleSetsToClear.push({ ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER, scope: currentScope });
        break;
    }
    if (currentScope === 'sender') {
      const RECEIVER_SCOPE: 'sender' | 'receiver' = 'receiver';
      ruleSetsToClear.push(
        ...[
          { ruleSet: RuleSet.DATA_TRANSFER_CONDITION, scope: RECEIVER_SCOPE },
          { ruleSet: RuleSet.DATA_TRANSFER, scope: RECEIVER_SCOPE },
          { ruleSet: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER, scope: RECEIVER_SCOPE },
        ]
      );
    }
    ruleSetsToClear.forEach((item) => clearRuleSet(item.ruleSet, item.scope));

    mainFlowContext[currentJurisdiction] = jurisdictionContext;
    context.patchState({ mainFlowContext });

    if (payload.followingCountries?.length > 0) {
      this.store.dispatch(
        new ClearFollowingRuleSets({
          jurisdiction: payload.followingCountries[0],
          followingCountries: [...payload.followingCountries].slice(1, payload.followingCountries.length),
        })
      );
    }
  }

  @Action(ClearMainFlowContext)
  public clearMainFlowContext(context: StateContext<ClearMainFlowContext>) {
    return context.patchState({ mainFlowContext: {} });
  }

  @Action(LoadContext)
  public loadDataTransferContext(context: StateContext<DataTransferStateModel>) {
    return this.contextService.getAppContext()
      .pipe(tap((result) => {
        if (result.configurationException) {
          this.store.dispatch(new SetErrors({ errors: { ...result.configurationException, stepsToGoBack: 2} }))
            .subscribe(() => this.router.navigate(['/errors']).then());
        } else {
          context.patchState({
            availableJurisdictions: result.jurisdictions,
            allJurisdictions: result.allJurisdictions,
          });
        }
        this.store
          .dispatch(new SetStaticLabels({ labels: result.staticLabels }))
          .subscribe(() => this.store.dispatch(new CompleteLoading()));
        this.store.dispatch(new SetTestMode({ testMode: result.testMode }));
      })
    );
  }

  @Action(LoadTerritorialScopeLabels)
  public loadTerritorialScopeLabels(
    _context: StateContext<DataTransferStateModel>,
    { payload }: LoadTerritorialScopeLabels
  ) {
    const jurisdiction = payload.jurisdiction;
    return this.dataTransferLabelsApiService
      .getTerritorialScopeLabels(jurisdiction)
      .pipe(
        switchMap((labels) =>
          this.store.dispatch(
            new LoadRuleSetLabels({ jurisdiction, labels, ruleSetType: RuleSet.TRANSFER_TERRITORIAL_SCOPING })
          )
        )
      );
  }

  @Action(LoadDataTransferLabels)
  public loadDataTransferLabels(_context: StateContext<DataTransferStateModel>) {
    const { senderJurisdictions, receiverJurisdictions } = _context.getState();

    const jurisdictions = [...new Set([...(senderJurisdictions || []), ...(receiverJurisdictions || [])])];
    jurisdictions.forEach((jurisdiction) => {
      combineLatest([
        this.dataTransferLabelsApiService.getDataClassificationLabels(jurisdiction),
        this.dataTransferLabelsApiService.getDataTransferConditionLabels(jurisdiction),
        this.dataTransferLabelsApiService.getDataTransferLabels(jurisdiction),
        this.dataTransferLabelsApiService.getSpecialContextDataTransferLabels(jurisdiction),
      ]).subscribe(
        ([
          dataClassificationLabels,
          dataTransferConditionLabels,
          dataTransferLabels,
          specialContextDataTransferLabels,
        ]) => {
          this.store.dispatch(
            new LoadRuleSetLabels({
              jurisdiction,
              labels: dataClassificationLabels,
              ruleSetType: RuleSet.DATA_CLASSIFICATION,
            })
          );
          this.store.dispatch(
            new LoadRuleSetLabels({
              jurisdiction,
              labels: dataTransferConditionLabels,
              ruleSetType: RuleSet.DATA_TRANSFER_CONDITION,
            })
          );
          this.store.dispatch(
            new LoadRuleSetLabels({ jurisdiction, labels: dataTransferLabels, ruleSetType: RuleSet.DATA_TRANSFER })
          );
          this.store.dispatch(
            new LoadRuleSetLabels({
              jurisdiction,
              labels: specialContextDataTransferLabels,
              ruleSetType: RuleSet.SPECIAL_CONTEXT_DATA_TRANSFER,
            })
          );
        }
      );
    });
  }

  @Action(StartAgain)
  public startAgain(context: StateContext<DataTransferStateModel>) {
    this.store.dispatch(new ClearMainFlowContext());
    context.setState(DEFAULT_STATE);
    return this.store.dispatch(new SetErrors());
  }

  @Action(SetCurrentJurisdiction)
  public setCurrentJurisdiction(context: StateContext<DataTransferStateModel>, { payload }: SetCurrentJurisdiction) {
    return context.patchState({ currentJurisdiction: payload });
  }

  @Action(SetCurrentRuleSet)
  public setCurrentRuleSet(context: StateContext<DataTransferStateModel>, { payload }: SetCurrentRuleSet) {
    return context.patchState({ currentRuleSet: payload.ruleSet });
  }

  @Action(SetCurrentScope)
  public setCurrentScope(context: StateContext<DataTransferStateModel>, { payload }: SetCurrentScope) {
    return context.patchState({ currentScope: payload });
  }

  @Action(InitNewJurisdictionFlow)
  public initNewJurisdictionFlow(context: StateContext<DataTransferStateModel>, { payload }: InitNewJurisdictionFlow) {
    context.dispatch(new SetCurrentRuleSet({ ruleSet: RuleSet.DATA_CLASSIFICATION }));

    if (payload.subjectOfInterestType === 'sender') {
      context.dispatch(new SetCurrentScope('sender'));
      context.dispatch(new SetCurrentJurisdiction(payload.jurisdiction));
      context.dispatch(new InitMainFlowJurisdiction(payload.jurisdiction));
    } else {
      context.dispatch(new SetCurrentScope('receiver'));
      context.dispatch(new SetCurrentJurisdiction(payload.jurisdiction));
      context.dispatch(new InitMainFlowJurisdiction(payload.jurisdiction));
    }
  }

  @Action(SetStaticResult)
  public setStaticResult(context: StateContext<DataTransferStateModel>, { payload }: SetStaticResult) {
    context.setState({
      ...payload.state,
    });
    return this.store.dispatch(new CompleteLoading());
  }
}
