import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ModelError } from 'src/generated/apps-api';
import { SetErrors } from '../../state/shared.action';
import { SharedSelectors } from '../../state/shared.selectors';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: [
    './modal-container.component.scss',
    './modal-container.phone.component.scss',
    './modal-container.tablet.component.scss'
  ],
})
export class ModalContainerComponent {
  constructor(public modal: NgbActiveModal, private store: Store) {}

  @Input()
  public title: string;
  @Input()
  public showCloseButton = true;
  @Input()
  public showBackButton = false;
  @Input()
  public closeEmitsLastBack = false;
  @Input()
  public showStartAgainButtonOnError = false;
  @Output()
  public titleClick = new EventEmitter<void>();
  @Output()
  public backButtonClick = new EventEmitter<void>();
  @Output()
  public startAgainClick = new EventEmitter<void>();
  @Output()
  public closeClick = new EventEmitter<void>();

    public errors = this.store.select<ModelError>(SharedSelectors.errors);

  clickTitle() {
    this.titleClick.emit();
  }

  clickStartAgain() {
    this.startAgainClick.emit();
  }

  close(){
    if(this.closeEmitsLastBack) {
      return this.closeClick.emit();
    }
    this.store.dispatch(new SetErrors());
    this.modal.dismiss('close');
  }
}
