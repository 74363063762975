import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Language, SupportedLanguages } from 'src/app/portal/model/supportedLanguages';
import { UserService } from 'src/app/portal/services/user.service';
import { AppWindow } from 'src/config/AppConfig.model';
import { LegalEntity } from 'src/generated/apps-api';
import { Option } from 'src/generated/apps-api/model/option';
import { PortalSelectors } from '../../../portal/state/portal.selectors';
import { UpdateTestMode, UpdateUserLanguage, UpdateUserLegalEntity } from '../../state/shared.action';
import { CountryOption } from '../../state/shared.model';
import { SharedSelectors } from '../../state/shared.selectors';

declare const window: AppWindow & Window;

@UntilDestroy()
@Component({
  selector: 'app-hamburger-settings',
  templateUrl: './hamburger-settings.component.html',
  styleUrls: ['./hamburger-submenu.component.scss', 'hamburger-submenu.tablet.component.scss'],
})
export class HamburgerSettingsComponent implements OnInit {
  constructor(private router: Router, public userService: UserService, public store: Store) { }

  @Output() isLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() goBack: EventEmitter<MouseEvent> = new EventEmitter();


    legalEntities$ = this.store.select<LegalEntity[]>(PortalSelectors.legalEntities);
    preferredLegalEntity$ = this.store.select<string>(SharedSelectors.preferredLegalEntityId);
    preferredLanguage$ = this.store.select<string>(SharedSelectors.preferredLanguage);
    isTestMode$ = this.store.select<boolean>(SharedSelectors.isTestMode);

  legalEntityOptions: CountryOption[];
  preferredLanguageOptions: CountryOption[];
  public isTestMode: boolean;

  ngOnInit(): void {
    this.legalEntities$.subscribe(
      le => {
        this.legalEntityOptions = this.legalEntityToCountryOption(le) as CountryOption[];
        this.isLoaded.emit(true);
      },
      error => console.log(error));

    const languageOptions: Language[] = [];
    for (const lng in SupportedLanguages) {
      languageOptions.push(this.getLanguageInformation(lng));
    }
    this.preferredLanguageOptions = this.languageToCountryOption(languageOptions);

    this.isTestMode = this.store.selectSnapshot(SharedSelectors.isTestMode);
    this.isTestMode$.pipe(untilDestroyed(this))
      .subscribe((t) => {
        this.isTestMode = t;
      });
  }

  private getLanguageInformation(language: string): Language {
    switch (language.toUpperCase()) {
      case SupportedLanguages.DE:
        return { label: 'German', isoCode: 'de', countryCode: 'DEU' };
      case SupportedLanguages.UK:
      default:
        return { label: 'English (UK)', isoCode: 'en', countryCode: 'GBR' };
    }
  }

  public handleChangeLegalEntity(event: CountryOption) {
    this.store.dispatch(new UpdateUserLegalEntity(event.value)).subscribe(() => this.router.navigateByUrl('/'));
  }


  public handleChangePreferredLanguage(event: Option) {
    this.store.dispatch(new UpdateUserLanguage(event.value)).subscribe(() => this.router.navigateByUrl('/'));
  }

  public toggle() {
    this.isTestMode = !this.isTestMode;
    this.store.dispatch(new UpdateTestMode({testMode: this.isTestMode}));
  }

  private legalEntityToCountryOption(le: LegalEntity | LegalEntity[]): CountryOption | CountryOption[] {
    return Array.isArray(le)
      ? le.map(l => ({ name: l.name, value: l.id, countryCode: l.countryCode }))
      : { name: le.name, value: le.id, countryCode: le.countryCode };
  }

  private languageToCountryOption(lng: Language | Language[]): CountryOption[] {
    return Array.isArray(lng)
      ? lng.map(l => ({ name: l.label, value: l.isoCode, countryCode: l.countryCode }))
      : [{ name: lng.label, value: lng.isoCode, countryCode: lng.countryCode }];
  }

}
