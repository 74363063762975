import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakOptions } from 'keycloak-angular/lib/core/interfaces/keycloak-options';
import { Observable, from, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { AppWindow } from 'src/config/AppConfig.model';
import { LoadUserPreferences, SetLoggedIn } from '../state/shared.action';
import { SharedSelectors } from '../state/shared.selectors';

const FALLBACK_CLIENT_ID = 'apps-web';
declare const window: AppWindow;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(protected keycloakService: KeycloakService, private ngZone: NgZone, private store: Store) {
  }

  public logIn(checkSso = false, clientId: string = FALLBACK_CLIENT_ID): Observable<boolean> {
    if (this.store.selectSnapshot(SharedSelectors.loggedIn)) {
      return of(true);
    }
    return this.ngZone.runOutsideAngular(() => {
      const config: KeycloakOptions = window.__config.keycloakConfig;
      //@ts-ignore
      config.config.clientId = clientId;
      if (checkSso) {
        config.initOptions.onLoad = 'check-sso';
      }
      return from(this.keycloakService.init(config)).pipe(
        first(),
        switchMap(() => from(this.keycloakService.isLoggedIn())),
        tap(isLoggedIn => {
          this.store.dispatch(new SetLoggedIn({ state: isLoggedIn }));
          if (isLoggedIn) {
            this.store.dispatch(new LoadUserPreferences());
          }
        }
        ));
    });
  }
}
