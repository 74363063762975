/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.58.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Role { 
    name?: string;
    nameLabelId?: string;
    type?: Role.TypeEnum;
    looping?: boolean;
}
export namespace Role {
    export type TypeEnum = 'MANDATORY' | 'OPTIONAL';
    export const TypeEnum = {
        MANDATORY: 'MANDATORY' as TypeEnum,
        OPTIONAL: 'OPTIONAL' as TypeEnum
    };
}


