/* eslint-disable no-underscore-dangle */

import { AppWindow } from 'src/config/AppConfig.model';

declare const window: AppWindow;

export const environment = {
  production: true,
  apiHost: window.__config.apiUrl,
  executionState: 'PROD',
  keycloak: {
    realm: window.__config.keycloakConfig.config.realm,
    clientId: window.__config.keycloakConfig.config.clientId,
    authServerUrl: window.__config.keycloakConfig.config.url,
    redirectUrl: window.__config.keycloakConfig.config.redirectUrl,
    onLoad: window.__config.keycloakConfig.initOptions.onLoad,
    'ssl-required': 'external',
    'public-client': true,
    'confidential-port': 0,
  }
};
