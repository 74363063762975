<app-modal-container
  class="label-resolver"
  [title]="currentTitle"
  [class.with-previous]="showLastBack || conceptTracker.length > 1"
  [showBackButton]="showLastBack || conceptTracker.length > 1"
  [closeEmitsLastBack]="closeEmitsLastBack"
  (backButtonClick)="goToPreviousConcept()"
  (closeClick)="lastBackClicked.emit()"
>
  <div class="label-resolver-container">
    <div
      [innerHtml]="currentContent | safeHtml"
      class="description"
      appLabelLinkClickListener
      [labelClick]="onResolvedLabelClick()"
      [context]="getContext()"
      [refreshListeners$]="refreshListeners$"
    ></div>
  </div>
</app-modal-container>
