import { StateContext } from '@ngxs/store';
import { LabelsObject, SharedStateModel, TaxonomyVariable } from '../shared.model';
import { cloneDeep } from 'lodash-es';

export class StoreRuleLabelUseCases {


  public assignNewRuleLabels(
    context: StateContext<SharedStateModel>,
    jurisdiction: string,
    ruleSetType: string,
    labels: LabelsObject,
    taxonomyVariables: TaxonomyVariable[],
    labelsWithResolvedVariables: {
      labelObject: LabelsObject,
      changedLabelKeys: string[]
    }
  ) {
    const changedLabelKey = labelsWithResolvedVariables.changedLabelKeys;

    const rulesLabels = cloneDeep(context.getState().rulesLabels);
    const jurisdictionLabels = rulesLabels[`${jurisdiction}:${ruleSetType}`] || {};

    rulesLabels[`${jurisdiction}:${ruleSetType}`] = Object.assign({}, jurisdictionLabels, labelsWithResolvedVariables.labelObject);

    return context.patchState({
      rulesLabels: rulesLabels,
      rulesLabelsWithTaxonomyVariables: this.getLabelsWithVariables(context, labels, jurisdiction, ruleSetType, changedLabelKey),
      taxonomyVariables: taxonomyVariables
    });
  }

  private getLabelsWithVariables(context: StateContext<SharedStateModel>, labels: LabelsObject, jurisdiction: string, ruleSetType: string, changedLabelKey: string[]) {
    const rulesLabelsWithTaxonomyVariables = cloneDeep(context.getState().rulesLabelsWithTaxonomyVariables);
    const cloneLabels = cloneDeep(labels);


    const jurisdictionLabelsWithTaxonomyVariables = rulesLabelsWithTaxonomyVariables[`${jurisdiction}:${ruleSetType}`] || {};
    rulesLabelsWithTaxonomyVariables[`${jurisdiction}:${ruleSetType}`] = Object.assign({}, jurisdictionLabelsWithTaxonomyVariables, this.filterLabelsWithVariables(cloneLabels, changedLabelKey));
    return rulesLabelsWithTaxonomyVariables;
  }


  private filterLabelsWithVariables(cloneLabels: LabelsObject, changedLabelKey: string[]) {
    return Object.keys(cloneLabels)
      .filter(key => changedLabelKey.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: cloneLabels[key]
        };
      }, {});
  }

}
