import { StateContext } from '@ngxs/store';
import { LabelsObject, SharedStateModel, TaxonomyVariable } from '../shared.model';
import { SetSharedResults, SetSharedResultsLabels } from '../shared.action';
import { replaceKnownVariablesFromLabels } from './replace-variables.utils';
import { TaxonomyVariablesApiService } from '../../../../generated/apps-api';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { cloneDeep } from 'lodash-es';

export class SetSharedResultsLabelsUseCases {


  public setLabels(context: StateContext<SharedStateModel>, { payload }: SetSharedResultsLabels) {
    const { staticLabels, rulesLabels, rulesLabelsWithTaxonomyVariables, taxonomyVariables } = payload?.state;

    if (!taxonomyVariables || taxonomyVariables.length == 0) {
      return context.patchState({
        staticLabels,
        rulesLabels
      });
    }

    return context.patchState({
      staticLabels,
      rulesLabels,
      rulesLabelsWithTaxonomyVariables: rulesLabelsWithTaxonomyVariables,
      taxonomyVariables: taxonomyVariables
    });

  }


}
