import { MarketingMaterialInstrument, MarketingMaterialResults, MMLegalEntity, Option, RuleSetsInfo, SupportedFootnotesAndDisclaimerLanguages } from 'src/generated/apps-api';
import { MarketingMaterialAttribute } from 'src/generated/apps-api/model/marketingMaterialAttribute';
import { MarketingMaterialExtendedQuestion } from '../models/marketingMaterialExtendedQuestion';

export interface MarketingMaterialStateModel {
  distributionCountries: string[];
  allCountries: string[];
  selectedDistributionCountries: string[];
  selectedDistributionCountriesWithResults: string[];
  selectedLegalEntitiesIdsWithResults: SelectedLegalEntity[];
  selectedLegalEntitiesMode: LegalEntityMode;
  legalEntities: MMLegalEntity[];
  attributes: MarketingMaterialAttribute[];
  ruleSetsInfo: RuleSetsInfo[];
  questions: MarketingMaterialExtendedQuestion[];
  results: MarketingMaterialResults;
  applicableCustomActionTagsOptions: Option[];
  applicablePerformanceDataOptions: Option[];
  customActionsTagValues: string[];
  performanceDataValues: string[];
  disclaimerLanguages: {
    supportedDisclaimerLanguages: SupportedFootnotesAndDisclaimerLanguages[];
    selectedDisclaimerLanguages: SelectedDisclaimerLanguage[];
  };
  instruments: MarketingMaterialInstrument;
  isSearchInstrumentEnable: boolean;
  generatedOn: string;
  generatedByFullName: string;
}

export enum LegalEntityMode {
  PER_COUNTRY = 'perCountry',
  PER_COMBINATION = 'perCombination',
}

export interface SelectedDisclaimerLanguage {
  id: string;
  jurisdiction: string;
  legalEntityId: string;
  language: string;
}

export interface SelectedLegalEntity {
  licenseCountry: string;
  legalEntityId: string;
}

export interface MMLegalEntityWithCountry extends MMLegalEntity {
  country?: string;
}
