<div [ngClass]="themeClass" class="tooltip-container">
  <ng-content></ng-content>
  <i
    #iconRef
    class="tooltip"
    [ngClass]="{ showing: !this.isHidden , small: size === 'small', large: size === 'large', rotate: type === '!' }"
    [inlineSVG]="iconText ? undefined : isCopy ? 'assets/content-copy.svg' :isAlert ? 'assets/alert.svg' : !isHidden ? 'assets/plus.svg' : 'assets/info.svg'"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    >{{ iconText }}
  </i>
  <div
    #tooltipRef
    *ngIf="helpText?.length > 0"
    class="tooltip-balloon"
    [ngClass]="{ showing: !isHidden, 'with-ellipsis': withEllipsis }"
    [innerHTML]="'<div>' + helpText + '</div>'"
  ></div>
</div>
