import { isEqual } from 'lodash-es';
import { MarketingMaterialAttribute, MarketingMaterialInstrument, MarketingMaterialQuestion, Option } from 'src/generated/apps-api';
import { MarketingMaterialExtendedQuestion } from '../models/marketingMaterialExtendedQuestion';
import { MarketingMaterialOption } from '../models/marketingMaterialOption';

export const mmIsInstrumentQuestion = (marketingMaterialExtendedQuestion: MarketingMaterialExtendedQuestion): boolean => {
  const instrumentDataQuestionsWithHint: string[] = ['salesRegistration', 'salesRegistrationType', 'fundDomicile', 'fundType', 'payingAgent', 'representative', 'exchangeTradedSecurity', 'SfdrproductType'];

  return instrumentDataQuestionsWithHint.includes(marketingMaterialExtendedQuestion.property);
};

export const convertOptionToInstrument = (option: Option): MarketingMaterialInstrument => {
  return {
    instrumentId: {
      type: 'ISIN',
      id: option.value,
    },
    name: option.nameLabelId,
  };
};

export const isIsinQuestion = (question: MarketingMaterialExtendedQuestion): boolean => {
  return question.property === 'fundQuestion';
};

export const hasIsinQuestion = (questions: MarketingMaterialExtendedQuestion[]): boolean => {
  return !!questions.find(q => q.property === 'fundQuestion');
};

export const shouldAddInstrumentQuestionHint = (
  isSearchInstrumentEnable: boolean,
  existingInstrumentsQuestions: MarketingMaterialExtendedQuestion[],
  question: MarketingMaterialExtendedQuestion,
  questions: MarketingMaterialExtendedQuestion[]
): boolean => {
  return (
    isSearchInstrumentEnable &&
    existingInstrumentsQuestions?.[0]?.property === question.property &&
    isEqual(existingInstrumentsQuestions?.[0]?.licenses, question.licenses) &&
    existingInstrumentsQuestions?.map(_q => _q.property).includes(question.property) &&
    !hasIsinQuestion(questions)
  );
};

export const updateDataAccordingToIsin = (
  option: MarketingMaterialOption,
  attributes: MarketingMaterialAttribute[],
  questions: MarketingMaterialExtendedQuestion[],
  questionParentProperty: string
): { questions: MarketingMaterialQuestion[]; attributes: MarketingMaterialAttribute[] } => {
  questions = questions.filter((_question, index) => index < questions.findIndex(_q => _q.property === questionParentProperty));

  const selectedOption: MarketingMaterialOption = {
    nameLabelId: option.name,
    value: option.value,
  };
  const fundQuestion: MarketingMaterialExtendedQuestion = {
    titleLabelId: 'fundQuestion',
    questionType: 'OTHER',
    property: 'fundQuestion',
    options: [selectedOption],
    licenses: questions[0].licenses,
  };

  questions.push(fundQuestion);

  // IF Instrument question is answer clean all next attributes
  if (attributes.find(a => a.name === questionParentProperty)) {
    attributes = attributes.filter((attr, index) => index < attributes.findIndex(_a => _a.name === questionParentProperty));
  }

  return { questions, attributes };
};

