import { LabelsObject, SharedStateModel } from './shared.model';

const defaultStaticLabels: LabelsObject = {
  goBackBtn: {
    id: 'goBackBtn',
    title: 'Go back',
    description: 'Button that allows the user to go back to the previous step'
  },
  supportBtn: {
    id: 'supportBtn',
    title: 'Support',
    description: 'Opens an email to support'
  },
  backToAppsBtn: {
    id: 'backToAppsBtn',
    title: 'Back to apps',
    description: 'Button to go back to the apps portal'
  },
  clientOnboardingApp: {
    id: 'clientOnboardingApp',
    title: 'Client Onboarding',
    description: 'All the documents and information you need for compliant client onboarding'
  },
  dataTransferApp: {
    id: 'dataTransferApp',
    title: 'Data Transfer',
    description:
      'Cut through the noise of Data Protection rules for transfer processing activities. All of your Data Protection questions about whether and how data can be transferred answered in a few clicks.'
  },
  noPermissionToEvaluateRuleErrorApp: {
    id: 'noPermissionToEvaluateRuleErrorApp',
    title: 'Sorry, there are no results for the information you presented',
    description: '<p>What went wrong? You lack the permissions to evaluate this country rule.</p><p>If the error persists, please contact our support team.</p>'
  },
  genericErrorApp: {
    id: 'genericErrorApp',
    title: 'Sorry, there are no results for the information you presented',
    description: 'If the error persists, please contact our support team.'
  },
  exportLinkExpiredError: {
    id: 'exportLinkExpiredError',
    title: 'Sorry, the page your looking for no longer exists',
    description: 'The link you are using has expired. Please ask for a new one.'
  },
  monitoringAccessError: {
    id: 'monitoringAccessError',
    title: 'Sorry, the access to this result has expired',
    description: 'To access an extended date range, please get in touch with your account executive.'
  },
  noPermissionError: {
    id: 'noPermissionError',
    title: 'Sorry, you have no permission to access this page',
    description: 'Unauthorized'
  },
  passwordDoesntMatchError: {
    id: 'passwordDoesntMatchError',
    title: 'Incorrect password',
    description: 'The password for the generated link does not match'
  }
};

export const DEFAULT_STATE: SharedStateModel = {
  staticLabels: defaultStaticLabels,
  rulesLabels: {},
  rulesLabelsWithTaxonomyVariables: {},
  errors: null,
  loaded: false,
  jumpedSteps: [],
  currentAppInfo: undefined,
  loggedIn: false,
  userPreferences: undefined,
  hasExternalGrant: false,
  whiteLabelConfig: {
    enabled: false
  },
  sharedResults: {},
  auditCorrelationId: undefined,
  skipNextErrorHandling: false,
  isWhiteLabelConfigAvailable: false,
  currentWhiteLabelCompanyId: undefined,
  taxonomyVariables: []
};
