import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Inject, Injector, NgModule, VERSION, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { InlineSVGModule } from 'ng-inline-svg';

import { buildApiConfig } from '../config/api-config';
import { ApiModule } from '../generated/apps-api';
import { AppsRoutingModule } from './apps-routing.module';
import { ClientOnboardingState } from './client-onboarding/state/client-onboarding.state';
import { CrossBorderInstrumentsState, CrossBorderState } from './cross-border/state';
import { CrossBorderAuditState } from './cross-border/state/cross-border-audit.state';
import { DataTransferState } from './data-transfer/state';
import { ErrorsModule } from './errors/errors.module';
import { MarketingMaterialState } from './marketing-material/state';
import { MarketingMaterialAuditState } from './marketing-material/state/marketing-material-audit.state';
import { PortalState } from './portal/state/portal.state';
import { RootComponent } from './shared/components/root/root.component';
import { GuardsModule } from './shared/guards';
import { PipeModule } from './shared/pipes/pipe-module.module';
import { ANALYTICS_SERVICE_TOKEN, AnalyticsService, CONFIG_SERVICE_TOKEN, ConfigService } from './shared/services';
import { SharedState } from './shared/state/shared.state';
import { UserService } from './portal/services/user.service';

@NgModule({
  declarations: [RootComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    KeycloakAngularModule,
    ApmModule,
    InlineSVGModule.forRoot(),
    ApiModule.forRoot(buildApiConfig),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppsRoutingModule,
    ErrorsModule,
    GuardsModule,
    PipeModule,
    NgxsModule.forRoot([ClientOnboardingState, DataTransferState, CrossBorderState, CrossBorderInstrumentsState, SharedState, CrossBorderAuditState, MarketingMaterialAuditState, MarketingMaterialState, PortalState]),
    RouterModule
  ],
  providers: [],
  bootstrap: [RootComponent],
})
export class AppModule {
  constructor(@Inject(CONFIG_SERVICE_TOKEN) configService: ConfigService, injector: Injector) {
    configService.load();
    configService.set('apm.serviceName', 'apps-web');
    configService.set('apm.serviceVersion', VERSION);
    const analytics = injector.get(ANALYTICS_SERVICE_TOKEN, new AnalyticsService(inject(ApmService), inject(KeycloakService), configService, inject(UserService)));
    analytics?.initialize();
  }
}
