import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { isEmpty } from 'lodash-es';
import { Activities } from 'src/app/shared/activities';
import { AppWindow } from 'src/config/AppConfig.model';
import { ApiaxKeycloakToken } from '../model/apiaxKeycloakToken.model';

declare const window: AppWindow & Window;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private keycloak: KeycloakService) {
  }

  public get photoUrl(): string {
    return `${window.__config.apiUrl}/api/portal/user-photo/${this.keycloakToken?.photoId}`;
  }

  public get keycloakToken(): ApiaxKeycloakToken {
    return this.keycloak.getKeycloakInstance()?.tokenParsed as ApiaxKeycloakToken;
  }

  public get name() {
    return `${this.keycloakToken?.firstName}`;
  }

  public get companyName(): string {
    return this.keycloakToken?.oname;
  }

  public get email() {
    return this.keycloakToken?.email;
  }

  public get organizationId(): string {
    return this.keycloakToken?.oid;
  }

  public get userId(): string {
    return this.keycloakToken?.uid;
  }

  public logout() {
    return this.keycloak.logout(window.location.href);
  }

  public get hasLanguageChangePermission(): boolean {
    return this.keycloakToken.activities.includes(Activities.AppsTranslations);
  }

  public get isTesterUser(): boolean {
    return this.keycloakToken.activities.includes(Activities.TestMode);
  }

  public reportUserInfo() {
    const token = this.keycloakToken;
    const posthog = window?.posthog;
    if (!(isEmpty(posthog) || isEmpty(token))) {
      posthog.identify(token.uid, { email: token.email });
      posthog.group('company', token.oid, { name: token.oname });
    }
  }

  public resetUserInfo() {
    const posthog = window?.posthog;
    if (!isEmpty(posthog)) {
      posthog.reset();
    }
  }
}
