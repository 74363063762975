<app-header *ngIf="!modalMode" [titleLabelId]="(appInfo | async)?.titleLabelId"></app-header>

<div [ngClass]="{ content: true, modalMode: modalMode }">
  <div class="title">{{ title | toLabel: labelContext }}</div>
  <div class="messages">
    <div [innerHtml]="description | safeHtml"></div>
  </div>

  <div class="buttons" *ngIf="isLoggedIn">
    <app-button *ngIf="showStartAgainButton" labelId="startAgainBtn" class="button-restart" type="secondary" icon="assets/reload.svg" (clicked)="emitStartAgain()" width="auto"></app-button>
    <app-button *ngIf="showGoBackButton" [labelId]="'goBackBtn'" [type]="'secondary'" class="button-restart" (clicked)="goBack()" width="auto"></app-button>
  </div>
</div>
