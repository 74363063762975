import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { orderBy } from 'lodash-es';
import { Option } from '../../../../generated/apps-api';
import { ToLabelPipe } from '../../pipes/to-label.pipe';
import { CountryOption } from '../../state/shared.model';
import { apiaxAlpha3ToAlpha2 } from '../../utils/convertalpha3toalpha2.utils';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss', './multiselect-dropdown.tablet.component.scss', './multiselect-dropdown.phone.component.scss']
})
export class MultiselectDropdownComponent implements OnChanges {
  constructor(private toLabelPipe: ToLabelPipe) {
  }

  public orderedOptions: Option[] = [];

  public readonly selectAllToggleOption: Option = {
    nameLabelId: 'mmSelectAllLabel',
    value: 'SELECT_ALL_TOGGLE_OPTION'
  };

  public readonly selectNoneOption: Option = {
    nameLabelId: 'mmAppStaticQuestionNoneOption'
  };

  @Input() options: CountryOption[];
  @Input() showOptionTooltip = false;
  @Input() selectAllToggle = false;
  @Input() ruleSet: string;
  @Input() placeholder: string;
  @Input() jurisdiction: string | string[];
  @Input() countrySelection = false;
  @Input() multiple = false;
  @Input() value: string | string[];
  @Input() darkTheme = false;
  @Input() disabled = false;
  @Input() contentProviderId: string;
  @Input() searchable = true;
  @Input() optionWarnings: { [key: string]: string };
  @Input() sortOptions: (options: Option[]) => Option[];
  @Input() dynamicOptions = false;
  @Input() multiSelectNotFoundText = 'No options found';
  @Input() isLoading = false;
  @Output() valueChanged: EventEmitter<Option> = new EventEmitter<Option>();
  @Output() multipleValueChanged: EventEmitter<Option[]> = new EventEmitter<Option[]>();
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('ngSelect')
  private selectComponent: NgSelectComponent;

  private selectedOption: CountryOption;

  ngOnChanges(changes: SimpleChanges) {
    const options: Option[] = changes.options?.currentValue;
    if (options) {
      this.orderedOptions = (this.selectAllToggle ? [this.selectAllToggleOption] : []).concat(this.orderOptions(options));
    }

    if (this.hasToggleNoneOption()) {
      const filteredValues = (this.value as string[])?.filter(v => !v?.includes(this.selectNoneOption.nameLabelId));
      const filteredOptions = this.options?.filter(o => !o?.value?.includes(this.selectNoneOption.nameLabelId));
      if (this.selectAllToggle && filteredValues?.length === filteredOptions?.length) {
        this.value = [this.selectAllToggleOption.value, ...this.value];
      }
    } else {
      if (this.selectAllToggle && (this.value as string[])?.length === this.options?.length) {
        this.value = [this.selectAllToggleOption.value, ...this.value];
      }
    }
  }

  search() {
    return (term: string, item: Option) => {
      // if we have the toggle one, we dont want to search the underlying labels
      const label = this.selectAllToggle
        ? ''
        : this.toLabelPipe.transform(item.value, {
          field: 'title',
          ruleSet: this.ruleSet,
          jurisdiction: this.jurisdiction
        });
      if (!this.dynamicOptions) {
        return label.toLowerCase().includes(term.toLowerCase()) || item.name?.toLowerCase().includes(term.toLowerCase());
      } else {
        return true;
      }
    };
  }

  onSearchInputChanged(search: { term: string; items: CountryOption[] }) {
    this.searchChanged.emit(search.term);
  }

  public orderOptions(options: Option[]): Option[] {
    if (this.sortOptions) {
      return this.sortOptions(options);
    } else {
      return orderBy(
        options,
        option =>
          option.name ??
          this.toLabelPipe.transform(option.nameLabelId ?? option.value, {
            field: 'title',
            ruleSet: this.ruleSet,
            jurisdiction: this.jurisdiction
          })
      );
    }
  }

  public onValueChanged(value: Option) {
    if (!this.multiple) this.valueChanged.emit(value);
    this.selectedOption = value;
  }

  public addToggleLogic(clickedItem: Option) {
    const toggleVal = this.selectAllToggleOption.value;
    const toggleNone = this.selectNoneOption.nameLabelId as string;

    if (this.hasToggleNoneOption()) {
      if (clickedItem.value.includes(toggleNone)) {
        this.value = this.getOptionsForToggleType('all').map(o => o.value);
      } else if (clickedItem.value === toggleVal) {
        this.value = [toggleVal].concat(this.getOptionsForToggleType('none').map(o => o.value));
      } else if ([...this.value].filter(v => !v.includes(toggleNone)).length === this.options.length - 1) {
        this.value = [toggleVal].concat(this.getOptionsForToggleType('none').map(o => o.value));
      } else {
        this.value = [...this.value].filter(v => !v.includes(toggleNone));
      }
      this.repositionDropdown();
    } else {
      if (clickedItem.value === toggleVal || this.value.length === this.options.length) {
        this.value = [toggleVal].concat(this.orderedOptions.map(o => o.value));
        this.repositionDropdown();
      }
    }
  }

  public removeToggleLogic(clickedItem: Option) {
    const toggleVal = this.selectAllToggleOption.value;
    const selectedVals = this.value as string[];

    let isToggledOff: boolean;
    if (this.hasToggleNoneOption()) {
      isToggledOff = clickedItem.value === toggleVal && selectedVals.length === this.options.length - 1;
    } else {
      isToggledOff = clickedItem.value === toggleVal && selectedVals.length === this.options.length;
    }
    this.value = isToggledOff ? [] : selectedVals.filter(v => v !== toggleVal);
    this.repositionDropdown();
  }

  private getOptionsForToggleType(toggleType: 'none' | 'all'): Option[] {
    if (toggleType === 'all') {
      return this.orderedOptions.filter(o => o.value?.includes(this.selectNoneOption.nameLabelId));
    } else {
      return this.orderedOptions.filter(o => !o.value?.includes(this.selectNoneOption.nameLabelId));
    }
  }

  private hasToggleNoneOption(): boolean {
    return this.options?.some(o => o.value?.includes(this.selectNoneOption.nameLabelId));
  }

  public onClose() {
    if (this.multiple) {
      this.multipleValueChanged.emit((this.value as string[])?.filter(key => key !== this.selectAllToggleOption.value).map(key => this.options.find(opt => opt.value === key)) || []);
    }
    this.focusOut.emit(this.value?.toString());
  }

  public getAlpha2(alpha3: string) {
    return apiaxAlpha3ToAlpha2(alpha3);
  }

  public isGlobal(alpha3: string) {
    return alpha3 === 'GLOBAL';
  }

  get themeClass() {
    return this.darkTheme ? 'theme-dark' : 'theme-light';
  }

  public handleOptionSelect(hide: boolean) {
    if(hide) {
      this.selectComponent.close();
    } else {
      this.selectComponent.open();
    }
  }
  public modalOpenFunction = (opened:boolean) => {
    this.handleOptionSelect(opened);
  }

  private repositionDropdown(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTimeout(() => (this.selectComponent as any)._onSelectionChanged());
  }
}
